.RejectDeleteContentTextarea{
    margin-top: 1vh;
    background-color: var(--light-grey);
    box-shadow: inset 1px 2px 6px #00000029;
    width: 100%;
    height: 14vh;
    resize: none;
    border-radius: 10px;
    outline: none;
    font-family: var(--poppins-regular);
    font-size: 1.1vw;
    color: var(--dark-grey4);
    padding: 1vh 1vw;
}
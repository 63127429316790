#editCompany {
    margin: 1vh 2vw;
}

.editCompanyImg {
    width: 15vw;
    margin-bottom: 3vh;
}

.editCompanyFormGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vh 4vw;
}

.editCompanyChkBoxMessageContainer {
    display: flex;
    width: 82%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1vw;
    margin: 1vh 0;
}

.editCompanyChkBox {
    width: 1.5VW;
}

.editCompanyFormItemText {
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1.5vw;
}

.editCompanyFormItemText2 {
    font-family: var(--poppins-light);
    color: var(--dark-grey);
    font-size: 1.4vw;
    line-height: 1.2;
}

.editCompanyFormItemInput {
    border: 0;
    box-shadow: inset 6px 8px 25px #00000029;
    width: 100%;
    height: 6vh;
    border-radius: 10px;
    font-size: 1.3vw;
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    padding: 0 1vw;
}

.editCompanyFormItem input[type='file'] {
    display: none;
}

.UploadLogoButton {
    background-color: var(--orange);
    color: var(--white);
    font-family: var(--poppins-medium);
    font-size: 1.4vw;
    width: 50%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.inputIsWrong {
    border: 1px solid var(--red);
}

.labelIsWrong {
    font-size: .9vw;
    color: var(--red);
    margin: 0;
}

.editCompanyLoadedImageText {
    font-family: var(--poppins-light);
    width: 100%;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.editCompanyLoadedImageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5vw;
    margin-bottom: 1vh;
    max-width: 40%;
}

.editCompanyLoadedImage {
    width: 1.5vw;
}

.employeeIncorrectFormat {
    color: red !important;
    font-size: 0.8vw !important;
    font-family: var(--poppins-light) !important;
}

.editCompanyUploadLogoContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 2vw;
    margin-top: 4vh;
}

.editCompanyFormSubitemText {
    font-family: var(--poppins-light);
    font-size: 1.05vw;
    color: var(--dark-grey);
}

.EditPQRSUserBtn {
    background-color: var(--white);
    color: var(--dark-grey);
}
@import '/src/App.css';

.entityValidationHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 24vW;
}

.entityValidationHeaderParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
    margin-bottom: -4px;
}

.hrEntityValidation {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: .50 !important;
    /* margin: 0.1rem 0 !important; */
    margin: 0.1rem 0 6rem 0 !important
}

.entityValidationMainContainer {
    margin: 0 2vw;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.entityValidationSubContainer1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.entityValidationInfoContainerL {
    width: max-content;
    height: 36vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.entityValidationInfoContainerR {
    width: max-content;
    height: 36vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.entityValidationInfoSubContainer {
    margin: 1vh 0;
}

.entityValidationInfoContainerTitle {
    font-family: var(--poppins-medium);
    font-size: 1.2vw;
    color: var(--dark-grey4);
}

.entityValidationInfoContainerP {
    font-family: var(--poppins-light);
    font-size: 1.2vw;
    color: var(--dark-grey4);
}

.entityValidationImageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.entityValidationImageSubContainer {
    /* border: 1px solid black; */
    -webkit-box-shadow: 0px 3px 7px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 3px 7px 2px rgba(0, 0, 0, 0.24);
    border-radius: 7px;
    margin-bottom: 3vh;
    width: 14vw;
    /* min-height: 38vh; */
}

.entityValidationImg {
    width: 14vw;
    /* height: 38vh; */
    border-radius: 7px;
    object-fit: contain;
    padding: 1vh 1vw;
}

.entityValidationEditIcon {
    position: absolute;
    width: 1.5vw;
    right: 26vw;
    cursor: pointer;
    margin: 1vh 1vw 0 1vw;
}

.entityValidationUserIcon {
    width: 20vw;
    display: none;
}

.entityValidationFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4vh;
}

.entityValidationFooterBackLink,
.entityValidationFooterBackLink:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
}

.lblButtonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 11vh;
    align-items: center;
    gap: .5vw;
}

.lblButtonContainer label,
.ccpathButtonContainer label{
    font-family: var(--poppins-regular);
    font-size: 1.1vw;
}

.ccpathButtonContainer{
    display: flex;
    align-items: center;
    margin-top: 1.5vh;
    gap: .5vw;
}

.entityValidationButtonStandard {
    background: none;
    color: var(--dark-grey3);
    border: 1px solid var(--dark-grey3);
    border-radius: 15px;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1vh 2.5vw;
}

.entityValidationButtonStandard:hover {
    background: var(--orange);
    color: var(--white);
    border: var(--orange);
}

.entityValidationButton,
.entityValidationButton:hover {
    background: var(--orange);
    color: var(--white);
    border: none;
    border-radius: 15px;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1vh 2.5vw;
    text-decoration: none;
}

.entityValidationButton:hover {
    cursor: pointer;
}

.entityValidationLabel {
    margin-left: 1vw;
    font-family: var(--poppins-light);
    font-size: 1.2vw;
    color: var(--dark-grey4);
}

.entityValidationBtnContainer {
    display: flex;
    flex-direction: row;
    width: 31vw;
    justify-content: space-between;
}
.ModalBackground {
    background: rgba(119, 36, 86, 0.6);
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 4;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.ModalBody {
    background-color: var(--dark-purple);
    width: 100%;
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;
}

.modalOpen {
    animation: slideDown .1s;
}

.modalHidden {
    display: none;
}

.modalIcon {
    width: 2.5vw;
    margin: 1vh 0;
}

.modalHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}


.modalSettled {
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1.3vw;
}

.ModalBody-content {
    font-size: 1.5vw;
    width: 60%;
    text-align: center;
}

.ModalBody-content p {
    font-family: var(--poppins-regular);
}

.ModalBody-content strong {
    font-family: var(--poppins-bold);
}

.ModalBody-buttons {
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    gap: 5vw;
}

.ModalBody-buttons button {
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    width: 15vw;
    height: 6vh;
    border-radius: 10px;
}

.Modal-normalBtn {
    background-color: var(--dark-purple);
    color: var(--white);
    border: 1px solid var(--white);
}

.Modal-orangeBtn{
    border: 0;
    background-color: var(--orange);
    color: var(--white);
}

.Modal-orangeBtn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.Modal-normalBtn:hover {
    color: var(--white);
    background-color: var(--orange);
}

.Modal-greenBtn {
    background-color: var(--green2);
    border: none;
    color: var(--white);
    max-width: 7.8vw;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-2vh);
    }

    to {
        opacity: 1;
    }
}
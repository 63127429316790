#requestsLayoutSize {
    height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.requestsLayout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 95%;
    gap: 20px;
    margin-left: 2vw;
}

.requestsLayoutNavLinksContainer {
    display: flex;
    gap: 1.5vw;
}

.requestsLayout a {
    text-decoration: none;
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-medium);
}

.requestsLayoutActiveLink {
    border-bottom: 5px solid var(--light-purple);
}

.requestsLayoutActiveLink p {
    font-family: var(--poppins-bold);
}

.requestsLayoutSeparator {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: 0.5 !important;
    margin: -0.2rem 0 !important;
    margin-bottom: 3vh !important;
}

.requestLayoutBackBox {
    background: transparent;
    position: absolute;
    display: flex;
    align-items: center;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    border: 0;
    bottom: 1vh;
    margin-left: 2vw;
}

.requestLayoutBackBox img {
    height: 5vh;
}

.requestLayoutBackBox span {
    color: var(--light-purple);
}

.entriesHeaderContainerUnreadPqrs {
    width: 1.3vw;
    height: 1.3vw;
    background-color: red;
    position: absolute;
    top: 0.2vw;
    right: 0.5vw;
    font-family: var(--quicksand-bold);
    color: var(--white);
    font-size: 0.6vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
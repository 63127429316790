/* #UserDetail {
  margin: 0 1vw;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#UserDetail-card {
  background-color: var(--light-grey);
  display: flex;
  margin-bottom: 12vh;
}

.UserDetail-card-lside {
  margin-top: 2vh;
  margin-right: 5vw;
}

.UserDetail-card-lside img {
  width: 25vw;
}

.UserDetail-card-rside {
  margin: 4vh 2vw;
}

.card-rside-title {
  font-family: var(--poppins-bold);
  color: var(--light-purple);
  margin-bottom: 4vh;
}

.card-rside-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.card-rside-grid div {
  width: 20vw;
}

.card-rside-key {
  font-family: var(--poppins-medium);
  font-size: 1.3vw;
}

.card-rside-value {
  font-family: var(--poppins-regular);
  font-size: 1.3vw;
}

.card-rside-button {
  background-color: var(--orange);
  color: var(--white);
  font-family: var(--poppins-medium);
  font-size: 1vw;
  border: 0;
  border-radius: 10px;
  width: 9vw;
  margin-right: 1vw;
  height: 5vh;
}

.UserDetailFooterContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 6vh;
} */
.userDetail {
  padding: 1vw;
  overflow-y: auto;
  height: 100%;
}

.userDetailPlanContainer {
  width: 100%;
  height: 100%;
}

.userDetailPlanImage {
  width: 100%;
  height: 100%;
}

.userDetailTitle,
.userDetailInfoTitle {
  font-family: var(--poppins-bold);
  color: var(--light-purple);
  font-size: 1.3vw;
  margin-bottom: 2vh;
}

.userDetailContent {
  display: grid;
  grid-template-columns: 22% 49% 26%;
  gap: 1vw;
  height: 90%;
}

.userDetailInfoContainer {
  background-color: var(--light-grey2);
  padding: 1vw;
  border-radius: 0.5vw;
  min-height: 15vh;
}

.userDetailSpecificInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .7vw;
}

.userDetailSpecificTitle {
  font-family: var(--poppins-medium);
  font-size: 1.3vw;
  color: var(--light-purple);
  margin: 0;
}

.userDetailSpecificDescription {
  font-family: var(--poppins-regular);
  font-size: 1.3vw;
  color: var(--black);
}

.userDetailGeneralContainer {
  margin-bottom: 1vw;
}

.userDetailInfoRequestText {
  font-family: var(--poppins-medium);
  font-size: 1.3vw;
  color: var(--black);
  line-height: 1.3;
  white-space: pre-line;
}

.RejectDeleteTitle{
  text-align: end;
}

.RejectDeleteTitleResponse{
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
}

.RejectDeleteTitleText{
  font-family: var(--poppins-medium);
  font-size: 1.1vw;
  color: var(--dark-grey4);
}

.userDetailInfoContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.5vh;
}

.userDetailAction {
  border: none;
  background-color: var(--orange);
  width: 100%;
  font-family: var(--poppins-medium);
  color: var(--white);
  font-size: 1.5vw;
  padding: 1vh 0;
  border-radius: 0.8vw;
}

.userDetailAction:disabled{
  background-color: var(--white);
  color: var(--dark-grey);
  border: 1px solid var(--dark-grey2);
}

.userDetailBackContainer,
.userDetailBackContainer:active,
.userDetailBackContainer:hover {
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--poppins-medium);
  font-size: 1.25vw;
  color: var(--light-purple);
}
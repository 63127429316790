.RejectChangePlanSectionTitle{
    display: flex;
    align-items: flex-end;
    gap: 1.5vw;
}

.RejectChangePlanSectionTitleResponse{
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--dark-grey4);
    margin: 0;
}

.RejectChangePlanSectionTitleText{
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1.1vw;
}

.RejectChangePlanSectionContentTextarea{
    margin-top: 1vh;
    background-color: var(--light-grey);
    box-shadow: inset 0px 3px 6px #00000029;
    width: 100%;
    height: 18vh;
    resize: none;
    border-radius: 10px;
    outline: none;
    font-family: var(--poppins-regular);
    font-size: 1.1vw;
    color: var(--dark-grey4);
    padding: 1vh 1vw;
}
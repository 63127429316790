.DocumentationProcessTableBox{
    border-radius: 11px;
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    height: 18vh;
    padding: 1vh 2vw;
    background-color: var(--light-grey);
    margin-bottom: 1.5vh;
    overflow-y: auto;
}

.DocumentationProcessTable{
    width: 100%;
    max-height: 80%;
    border-collapse: separate;
    border-spacing: 0 5px;
}

.DocumentationProcessTable thead {
    position: sticky;
    top: 0;
    background-color: var(--light-grey);
    z-index: 2;
}

.DocumentationProcessTitle{
    color: var(--light-purple);
    font-family: var(--poppins-semibold);
    font-size: 1.25vw;
    height: 4vh;
}

/* .DocumentationProcessRow{
    position: sticky;
    top: 10vh;
    background-color: var(--light-grey);
} */

.DocumentationProcessText{
    padding: 0 1vw;
    color: var(--dark-grey4);
    font-family: var(--poppins-regular);
    font-size: 1.25vw;
    height: 1.5vh;
}

.DocumentationSubProcessText{
    padding-left: 3vw;
    color: var(--dark-grey4);
    font-family: var(--poppins-regular);
    font-size: 1.25vw;
    height: 1.5vh;
}

.DocumentationProcessDownloadButton{
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    border: 0;
    background-color: transparent;
}

.DocumentationProcessDownloadButton img{
    width: 1.5vw;
}

.DocumentationProcessDropdown{
    display: flex;
    border: 0;
    gap: .4vw;
}

.DocumentationProcessDropdown p{
    color: var(--dark-grey4);
}

.DocumentationProcessDropdown img{
    width: 100%;
    height: 100%;
}

.DocumentationDropdownArrow{
    width: 1.5vw;
}

.dropdownOpen{
    transform: rotate(180deg);
    transition: .2s;
}

.dropdownClosed{
    transition: .2s;
}

.DocumentationSubProcess{
    background-color: var(--dark-grey6);
}

.DocumentationProcessCalendarButton{
    color: #fff;
    border: 0;
    font-family: var(--poppins-medium);
    width: 1.5vw;
    margin-left: .5vw;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
}

.DocumentationProcessCalendarButton img{
    width: 100%;
}

.DocumentationProcessBSide{
    display: flex;
    justify-content: space-between;
}

.DocumentationProcessBSideLSide{
    width: 40%;
    padding: 4vh 0;
    border-radius: 11px;
}

.DocumentationProcessBSideLSideBox{
    width: 75%;
    margin: 0 auto;
}

.DocumentationProcessBSideLSideBox p{
    text-align: center;
    margin-bottom: 1vh;
}

.DocumentationProcessBSideLSideBoxLSide,
.DocumentationProcessBSideLSideBoxRSide{
    width: 50%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
}

.DocumentationProcessBSideCSide{
    width: 40%;
    padding: 4vh 0;
    display: flex;
    justify-content: center;
}

.DocumentationProcessBSideCSideBox{
    padding: 5vh 0;
    width: 70%;
}

.DocumentationProcessBSideText{
    font-family: var(--poppins-regular);
    color: var(--dark-grey4);
    font-size: 1vw;
    line-height: 2vh;
}

#uploadAnnexes{
    display: none;
}

.SelectProcessUploadButtonImg{
    width: 1.3vw;
    margin-left: 1vw;
}

.DocumentationProcessBSideBtn{
    color: #fff;
    background-color: var(--orange);
    border: 0;
    font-family: var(--poppins-medium);
    font-size: 1.56vw;
    width: 100%;
    padding: .7vh 0;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
}

.DocumentationProcessBSideBtn:disabled{
    background-color: #fff;
    color: var(--dark-grey3);
    border: 1px solid var(--dark-grey2);
    cursor: default;
}

.DocumentationProcessBSideBtn:disabled img{
    display: none;
}

.DocumentationProcessBSideMessage{
    display: none;
    font-family: var(--poppins-regular);
    font-size: 1.04vw;
    color: var(--dark-grey4);
    width: 16vw;
    white-space: nowrap;
    overflow: hidden;
}

.DocumentationProcessBSideMessage img{
    width: 1.2vw;
    margin-right: .5vw;
}

.isVisible {
    display: flex;
}

.DocumentationProcessBSideRSideBtnBox{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    margin-top: 1vh;
}

.DocumentationProcessBSideRSide{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    padding: 4vh 0;
}

.DocumentationProcessBSideRSideBox{
    width: 70%;
    margin: 0 auto;
}
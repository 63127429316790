.UpdateProcessTSide{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5vw;
}

.UpdateProcessTSideLSide{
    background-color: var(--light-grey);
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 2vh 1vw;
}

.UpdateProcessListBox{
    overflow-y: auto;
    max-height: 20vh;
}

.UpdateProcessTSideRSide{
    background-color: var(--light-grey);
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 2vh 1vw;
}

.UpdateProcessBoxFiles{
    display: flex;
    justify-content: space-between;
    height: 85%;
    padding: 0 1.5vw;
}

.UpdateProcessBoxUpdateDate{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.UpdateProcessInputBox{
    display: flex;
    align-items: center;
}

.UpdateProcessCheck{
    width: 1.3vw;
    height: 1.3vw;
    appearance: none;
    background-color: #fff;
    border: 1px solid var(--orange);
    border-radius: 3px;
    cursor: pointer;
    margin-right: .5vw;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-size: 1.5vw;
    background-position: center;
}

.UpdateProcessCheck:checked{
    background-color: var(--orange);
}

.UpdateProcessBoxText{
    font-family: var(--poppins-regular);
    font-size: 1.25vw;
    color: var(--dark-grey4);
}

.UpdateProcessBoxFile{
    display: flex;
    align-items: center;
}

.UpdateProcessBoxFileName{
    font-family: var(--poppins-regular);
    font-size: 1.25vw;
    color: var(--dark-grey4);
    width:7vw;
}

.UpdateProcessBoxFileRemoveBtn {
    width: 1vw;
    height: 1vw;
    margin-left: .5vw;
    display: flex;
    border: 0;
}

.UpdateProcessRSide{
    background-color: var(--light-grey);
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 2vh 1vw;
}

.UpdateProcessTitle{
    color: var(--light-purple);
    font-size: 1.56vw;
    font-family: var(--poppins-bold);
}

.UpdateProcessBSide{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2vh;
    gap: 1vh;
}
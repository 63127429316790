.usersAdminDetails {
    overflow-y: auto;
    padding: 1vh 2vw;
  }
  
  .usersAdminDetailsTitle,
  .usersAdminDetailsTitleControl {
    font-family: var(--poppins-bold);
    font-size: 1.25vw;
    color: var(--light-purple);
  }
  
  .usersAdminDetailsTitleControl {
    color: #AEAEAE;
  }
  
  .usersAdminDetailsMainContent {
    height: 65vh;
    display: grid;
    gap: 2vw;
    grid-template-rows: 50% 45%;
  }
  
  .usersAdminDetailsContainer1 {
    display: grid;
    gap: 1vw;
    grid-template-columns: 22% 50% 23%;
  }
  
  .usersAdminDetailsPrincipalImgContainer {
    width: 100%;
  }
  
  .usersAdminDetailsPrincipalImg {
    width: 100%;
    object-fit: cover;
  }
  
  .usersAdminDetailsSubcontainer {
    background-color: var(--light-grey2);
    padding: 2vh 1vw .5vh 1vw;
    border-radius: 1vw;
  }
  
  .usersAdminDetailsInfoContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5vw;
  }

  .usersAdminDetailsInfoPlanImg{
    width: 9vw;
  }
  
  .usersAdminDetailsSubtitle {
    font-family: var(--poppins-medium);
    font-size: 1.1vw;
    color: var(--light-purple);
    margin-bottom: 0;
  }
  
  .usersAdminDetailsInfoData {
    font-family: var(--poppins-regular);
    font-size: 1.1vw;
    color: var(--black);
  }
  
  .usersAdminDetailsInfoContainerColumn {
    display: grid;
    gap: 0.5vw;
  }
  
  .usersAdminDetailsContainer2 {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 1vw;
  }
  
  .usersAdminDetailsSubcontainer {
    overflow-y: auto;
  }
  
  .usersAdminDetailsHistorialRows {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1vw;
  }
  
  .usersAdminDetailsCompanyName
  .usersAdminDetailsSubtitleHistorial {
    text-align: center;
  }
  
  .usersAdminDetailsCompanyName {
    font-family: var(--poppins-medium);
    color: #5A5A5A;
    font-size: 1.25vw;
    padding-left: 2vw;
  }
  
  .usersAdminDetailsCompanyStatus {
    display: flex;
    justify-content: center;
  }
  
  .usersAdminDetailsCompanyStatusImg {
    width: 2vw;
  }
  
  .usersAdminDetailsSubtitleHistorialLSide {
    margin: 1vw 0 0 2vw;
    text-align: start;
  }

  .usersAdminDetailsSubtitleHistorialRSide {
    margin-top: 2vh;
    margin-left: 0;
    text-align: center;
  }

  .usersAdminDetailsSubtitleHistorialState {
    margin-top: 1vw;
    margin-bottom: 0;
    text-align: center;
  }
  
  .usersAdminDetailsContainer3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1vh;
  }
  
  .usersAdminDetailsButton {
    border: none;
    background-color: var(--orange);
    padding: 0.5vw 3vw;
    font-family: var(--poppins-medium);
    font-size: 1.25vw;
    color: var(--white);
    margin-left: 0.5vw;
    border-radius: 0.5vw;
  }

  .usersAdminDetailsButton:disabled{
    background-color: var(--white);
    color: var(--dark-grey);
    border: 1px solid var(--dark-grey2);
  }
  
  .usersAdminDetailsBackContainer,
  .usersAdminDetailsBackContainer:active,
  .usersAdminDetailsBackContainer:hover {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--poppins-medium);
    font-size: 1.25vw;
    color: var(--light-purple);
  }
  
  .usersAdminDetailsNA {
    margin-top: 6vh;
  }

  .greyColor{
    color: var(--dark-grey2);
  }